import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import YouTube from 'react-youtube'
import SideModal from '../side-modal/side-modal'
import { SideModalCommonProps } from './types'

type WelcomeSideModalProps = SideModalCommonProps

const WelcomeSideModal: React.FC<WelcomeSideModalProps> = ({ isOpen, onClose }) => {
  return (
    <SideModal isOpen={isOpen} onClose={onClose} title={''} showOverlay>
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div>
          <div className="fs-2 mb-5">Welcome</div>
          <YouTube
            videoId="kat2MsiBRoM"
            opts={{
              width: 600,
              playerVars: {
                autoplay: 1,
                rel: 0,
                modestbranding: 0,
                showinfo: 0,
              },
            }}
            iframeClassName="iframe-max-width"
          />
          <Button className="mt-5" as={Link} to="/">
            Today, I komit to...
          </Button>
        </div>
      </div>
    </SideModal>
  )
}

export default WelcomeSideModal
