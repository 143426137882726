import React from 'react'
import YouTube from 'react-youtube'
import SideModal from '../side-modal/side-modal'
import { SideModalCommonProps } from './types'
import { Podcast } from '../../../../backend/src/models/podcasts.model'
import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Paginated } from '@feathersjs/feathers/lib'
import { fetch } from '../../core/queries'

interface PodcastSideModalProps extends SideModalCommonProps {
  podcast: Podcast
  title?: string
  titleSmall?: string
}

interface VideoProps {
  videoId: string
  title?: string
  titleSmall?: string
}

const Video: React.FC<VideoProps> = ({ title, titleSmall, videoId }) => (
  <div>
    {title && (
      <div className="my-5">
        <div className="fs-2">{title}</div>
        {titleSmall && <div className="text-secondary">{titleSmall}</div>}
      </div>
    )}
    <YouTube
      videoId={videoId}
      opts={{
        width: 600,
        playerVars: {
          autoplay: 0,
          rel: 0,
        },
      }}
      iframeClassName="iframe-max-width"
    />
  </div>
)

const PodcastSideModal: React.FC<PodcastSideModalProps> = ({
  isOpen,
  onClose,
  podcast,
  title,
  titleSmall,
}) => {
  const [showAllVideos, setShowAllVideos] = React.useState(false)
  //Fetch the current day
  const podcastsQuery = useQuery<any, unknown, Paginated<Podcast>, ['podcasts', any]>(
    ['podcasts', { $sort: '-userDay', isApp: true }], //TODO: is App
    fetch,
  )

  React.useEffect(() => {
    !isOpen && setShowAllVideos(false)
  }, [isOpen])

  return (
    <SideModal isOpen={isOpen} onClose={onClose} showOverlay>
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div style={{ height: showAllVideos && '100%' }}>
          <div>
            <Video title={title} titleSmall={titleSmall} videoId={podcast.youtubeId} />
          </div>
          {showAllVideos && (
            <div>
              {(podcastsQuery.data?.data || [])
                .filter(p => p._id !== podcast._id)
                .map(podcast => (
                  <div key={podcast._id}>
                    <Video title={`Day ${podcast.userDay}`} videoId={podcast.youtubeId} />
                  </div>
                ))}

              <Video title="Welcome" videoId="kat2MsiBRoM" />
            </div>
          )}
          {!showAllVideos && (
            <>
              <Button className="mt-5" onClick={onClose}>
                Today, I komit to...
              </Button>
              <div className="mt-4">
                <Button
                  variant="link"
                  className="text-secondary text-decoration-none text-muted"
                  onClick={e => {
                    e.stopPropagation()
                    setShowAllVideos(!showAllVideos)
                  }}
                >
                  All videos...
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </SideModal>
  )
}

export default PodcastSideModal
